import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
};

export const HomeIcon = ({ viewBoxSize }: TProps) => {
  const defaultViewBoxSize = 24;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M12.3333 18.4439H17.3333V10.9256L11.5 6.38891L5.66667 10.9256V18.4439H10.6667V13.4439H12.3333V18.4439ZM19 19.2772C19 19.4983 18.9122 19.7102 18.7559 19.8665C18.5996 20.0228 18.3877 20.1106 18.1667 20.1106H4.83333C4.61232 20.1106 4.40036 20.0228 4.24408 19.8665C4.0878 19.7102 4 19.4983 4 19.2772V10.5189C3.99991 10.3919 4.02885 10.2666 4.0846 10.1525C4.14035 10.0384 4.22143 9.93855 4.32167 9.86058L10.9883 4.67558C11.1346 4.56178 11.3147 4.5 11.5 4.5C11.6853 4.5 11.8654 4.56178 12.0117 4.67558L18.6783 9.86058C18.7786 9.93855 18.8597 10.0384 18.9154 10.1525C18.9712 10.2666 19.0001 10.3919 19 10.5189V19.2772Z"
        fill="#404040"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

export enum EWHOS_ROUTES {
  MAIN_URL = "MAIN_URL",
  SEARCH_URL = "SEARCH_URL",
  PROJECT_ARCHITECTURE_URL = "PROJECT_ARCHITECTURE_URL",
  PROJECT_INTERIOR_URL = "PROJECT_INTERIOR_URL",
  PROJECT_ARCHITECTURE_DETAIL = "PROJECT_ARCHITECTURE_DETAIL",
  PROJECT_INTERIOR_DETAIL = "PROJECT_INTERIOR_DETAIL",
  EXPERTS_URL = "EXPERTS_URL",
  EXPERTS_DETAIL_URL = "EXPERTS_DETAIL_URL",
  MATERIAL_LIST_URL = "MATERIAL_LIST_URL",
  MATERIAL_DETAIL_URL = "MATERIAL_DETAIL_URL",
  NEWS_LETTER_URL = "NEWS_LETTER_URL",
  CONTACT_URL = "CONTACT_URL",
  MYPAGE_SETTING_URL = "MYPAGE_SETTING_URL",
  MYPAGE_SCRAP_URL = "MYPAGE_SCRAP_URL",
}

import styled from "styled-components";

type TProps = {
  viewBoxHeight: number;
};

export const DesignWhosTextLogo = ({ viewBoxHeight }: TProps) => {
  const defaultViewBoxWidth = 187;
  const defaultViewBoxHeight = 22;
  const viewBoxWidth = (viewBoxHeight / defaultViewBoxHeight) * defaultViewBoxWidth;
  const ratio = viewBoxHeight / defaultViewBoxHeight;
  return (
    <Logo
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxWidth}
      height={viewBoxHeight}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M0.984375 20.9647V0.992188H7.34353C13.1707 0.992188 16.6121 4.7181 16.6121 10.9252C16.6121 17.1323 13.1125 20.9647 7.00271 20.9647H0.984375ZM6.89465 19.1959C12.0485 19.1959 14.6836 16.076 14.6503 10.917C14.6503 5.8154 12.0983 2.75278 7.22715 2.75278H2.97109V19.1959H6.89465Z"
        fill="black"
      />
      <path
        d="M20.5391 0.992188H32.4178V2.76097H22.5341V10.0982H31.8027V11.8342H22.5341V19.2041H32.6173V20.9729H20.5474V0.992188H20.5391Z"
        fill="black"
      />
      <path
        d="M42.917 2.44697C40.1156 2.44697 38.212 3.99465 38.212 6.09099C38.212 8.18733 40.2819 9.12904 42.2187 9.65313L44.3218 10.2345C46.8738 10.9552 49.8663 12.249 49.8663 15.6146C49.8663 18.9802 47.2312 21.2976 42.784 21.2976C38.611 21.2976 35.951 19.1194 35.7266 15.9176H37.7715C37.971 18.2596 40.099 19.5288 42.784 19.5288C45.7765 19.5288 47.9378 17.9566 47.9378 15.5818C47.9378 13.592 46.2005 12.683 43.7399 11.9951L41.3293 11.3073C38.1372 10.3656 36.2586 8.76874 36.2586 6.17288C36.2586 2.91373 39.168 0.710938 42.9835 0.710938C46.799 0.710938 49.4839 2.94649 49.6169 6.0091H47.6552C47.4557 3.83088 45.5521 2.44697 42.9253 2.44697H42.917Z"
        fill="black"
      />
      <path d="M55.7758 20.9647H53.7891V0.992188H55.7758V20.9647Z" fill="black" />
      <path
        d="M68.4563 2.56161C64.566 2.56161 61.6483 5.64879 61.6483 10.9797C61.6483 16.3106 64.5328 19.3978 68.5976 19.3978C72.2136 19.3978 74.7739 16.9576 74.8155 12.9696H69.0216V11.2336H76.7523V12.945C76.7523 18.0467 73.3358 21.2485 68.6059 21.2485C63.2526 21.2485 59.7031 17.2523 59.7031 10.9879C59.7031 4.72346 63.3108 0.710938 68.4563 0.710938C72.8537 0.710938 75.9626 3.63435 76.6359 7.22105H74.5661C73.8346 4.60063 71.7065 2.56161 68.4646 2.56161H68.4563Z"
        fill="black"
      />
      <path
        d="M96.6642 20.9647H94.7606L82.9733 4.497H82.807V20.9647H80.8203V0.992188H82.7488L94.5361 17.4927H94.7024V0.992188H96.6642V20.9647Z"
        fill="black"
      />
      <path
        d="M99.5234 0.992188H104.12L107.537 14.9541H107.703L111.427 0.992188H115.376L119.1 14.9787H119.266L122.682 0.992188H127.279L121.485 20.9647H117.371L113.505 7.78072H113.339L109.449 20.9647H105.334L99.5401 0.992188H99.5234Z"
        fill="black"
      />
      <path
        d="M129.438 0.992188H133.635V9.27108H142.43V0.992188H146.661V20.9647H142.43V12.6858H133.635V20.9647H129.438V0.992188Z"
        fill="black"
      />
      <path
        d="M159.181 21.2321C153.777 21.2321 149.688 17.4489 149.688 10.9715C149.688 4.49417 153.777 0.710938 159.181 0.710938C164.584 0.710938 168.649 4.46142 168.649 10.9715C168.649 17.4816 164.534 21.2321 159.181 21.2321ZM159.181 4.37134C156.047 4.37134 153.968 6.68878 153.968 10.9633C153.968 15.2379 156.038 17.5553 159.181 17.5553C162.323 17.5553 164.359 15.2379 164.359 10.9633C164.359 6.68878 162.314 4.37134 159.181 4.37134Z"
        fill="black"
      />
      <path
        d="M179.179 4.18337C177.051 4.18337 175.871 5.14965 175.846 6.47624C175.821 7.90929 177.359 8.57258 179.096 8.95745L180.916 9.39965C184.391 10.1694 186.993 11.9136 186.993 15.2465C186.993 18.9151 184.084 21.2325 179.154 21.2325C174.225 21.2325 171.116 18.9969 171.008 14.7224H175.098C175.239 16.7368 176.86 17.7604 179.104 17.7604C181.349 17.7604 182.745 16.7368 182.745 15.2465C182.745 13.8953 181.482 13.2566 179.271 12.7079L177.059 12.1838C173.643 11.3568 171.54 9.66988 171.54 6.74647C171.54 3.10245 174.79 0.703125 179.212 0.703125C183.635 0.703125 186.66 3.15977 186.719 6.68915H182.687C182.521 5.1169 181.232 4.17518 179.187 4.17518L179.179 4.18337Z"
        fill="black"
      />
    </Logo>
  );
};

const Logo = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

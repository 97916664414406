import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

export const useResponsiveBreakpoint = () => {
  const [isMobile, setisMobile] = useState(false);
  const isMediaQueryMobile = useMediaQuery({ query: "(max-width: 767px)" });

  useEffect(() => {
    if (isMediaQueryMobile) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  }, [isMediaQueryMobile]);

  const isDesktop = !isMobile;

  return { isMobile, isDesktop };
};

import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
  viewBoxHeightOffset?: number;
};

export const DesignWhosSquareLogo = ({ viewBoxSize, viewBoxHeightOffset = 0 }: TProps) => {
  const defaultViewBoxSize = 26;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize + viewBoxHeightOffset}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize + viewBoxHeightOffset}`}
      fill="none"
      $ratio={ratio}
    >
      <path d="M25.77 0H0V25.77H25.77V0Z" fill="#F35935" />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

import { getInternalIp } from "@/api/ip/ip.api";
import { THackleEvent } from "@/types/common.hackle-type";
import { createInstance } from "@hackler/react-sdk";
import { HackleReactSDKClient } from "@hackler/react-sdk/lib/client";

const config = {
  debug: true,
};

export let hackleClient: null | HackleReactSDKClient = null;

const initializeHackleClient = async () => {
  try {
    const response = await getInternalIp();

    if (response === false) {
      hackleClient = createInstance(process.env.NEXT_PUBLIC_SDK_HACKLE_KEY || "", config);
    }
  } catch (error) {
    console.error("Hackle client initialization failed:", error);
  }
};

export const generateHackleEvent = (key: string, properties: any) => {
  return {
    key,
    properties,
  };
};

export const generateAndTrackHackleEvent = <K extends keyof THackleEvent>(
  key: K,
  properties: THackleEvent[K]
) => {
  const event = {
    key,
    properties,
  };
  hackleClient?.track(event);
};

initializeHackleClient();

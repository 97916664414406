import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
  upsideDown?: boolean;
};

export const ArrowDownIcon = ({ viewBoxSize, upsideDown }: TProps) => {
  const defaultViewBoxSize = 24;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
      $upsideDown={!!upsideDown}
    >
      <path
        d="M15 11L12.1414 13.8586C12.0633 13.9367 11.9367 13.9367 11.8586 13.8586L9 11"
        stroke="#404040"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number; $upsideDown: boolean }>`
  transform: ${({ $upsideDown }) => $upsideDown && "rotate(-180deg)"};
  transition: 0.2s;
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

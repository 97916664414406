import { EPARTNER_CATEGORY, EPARTNER_SORT } from "@/domain/partner/enums/partner.enum";
import { setOptions } from "@/utils/common.util";
import {
  TGetPartnerListReqQueryParams,
  TGetPartnerProjectListReqQueryParams,
} from "../api/partner.type";

export const PARTNER_CATEGORIES = {
  [EPARTNER_CATEGORY.ARCHITECTURE]: "건축",
  [EPARTNER_CATEGORY.INTERIOR]: "인테리어",
  [EPARTNER_CATEGORY.CONSTRUCTION]: "시공사",
  [EPARTNER_CATEGORY.PHOTO]: "포토",
} as const;

export const PARTNER_SORT = {
  [EPARTNER_SORT.NAME_ASC]: "이름순",
  [EPARTNER_SORT.HITS_DESC]: "인기순",
  [EPARTNER_SORT.PROJECTS_DESC]: "프로젝트순",
};

export const PARTNER_LIST_LIMIT = 20;
export const PARTNER_PROJECT_LIST_LIMIT = 12;

export const GET_PARTNER_LIST_REQ_QUERY_PARAMS: TGetPartnerListReqQueryParams = {
  page: 1,
  category: EPARTNER_CATEGORY.ARCHITECTURE,
  sort: EPARTNER_SORT.NAME_ASC,
};

export const GET_PARTNER_PROJECT_LIST_REQ_QUERY_PARAMS: TGetPartnerProjectListReqQueryParams = {
  page: 1,
};

export const PARTNER_CATEGORIES_OPTIONS = setOptions(PARTNER_CATEGORIES);
export const PARTNER_SORT_OPTIONS = setOptions(PARTNER_SORT);

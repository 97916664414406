import * as Sentry from "@sentry/nextjs";
import { AxiosError } from "axios";

export const logApiErrorWithSentry = (e: AxiosError) => {
  if (!e.config || !e.response || !e.request) {
    Sentry.captureException(e);
    return;
  }

  const { baseURL, method, url, params, data, headers: _headers } = e.config;
  const { data: responseData, status } = e.response;
  const headers: { [key: string]: any } = {};

  Object.entries(_headers).forEach(([key, value]) => (headers[key] = value));

  Sentry.withScope((scope) => {
    scope.setContext("API Request Detail", {
      method,
      url,
      params,
      data,
      headers,
    });
    scope.setContext("API Response Detail", {
      status,
      responseData,
    });
    scope.setTag("type", "axiosError");
    scope.setFingerprint([method!, status.toString(), url!]);
    Sentry.captureException(new Error(`${baseURL}${url} (${status})`));
  });
};

import { useMemberAuth, useMemberInfo } from "@/domain/member/api/member.query";
import { EMEMBER_ROLES } from "@/domain/member/enums/member.enum";
import { THackleLoginUserProperties } from "@/types/common.hackle-type";
import * as Sentry from "@sentry/nextjs";
import dayjs from "dayjs";
import { useMemo } from "react";

export const useUserInfo = () => {
  const { data: memberAuthData } = useMemberAuth();
  const isLogin = !!memberAuthData;

  const isLoginOut = !isLogin;

  const { data: memberInfoData } = useMemberInfo(isLogin);

  const isNormalMember = !!memberInfoData?.roles?.includes(EMEMBER_ROLES.NORMAL);
  const isCorperationMember = !!memberInfoData?.roles?.includes(EMEMBER_ROLES.CORPORATION);
  const hackleLoginUserProperties: THackleLoginUserProperties = useMemo(
    () =>
      memberInfoData
        ? {
            User_ID: isNormalMember ? memberInfoData.phoneNumber : memberInfoData.email,
            User_Type: isNormalMember ? 1 : 2,
            MKT_Kakao: memberInfoData.kakaoMarketing ? "O" : "X",
            MKT_SMS: memberInfoData.smsMarketing ? "O" : "X",
            MKT_Email: memberInfoData.mailMarketing ? "O" : "X",
            Join_Date: dayjs(memberInfoData.createdAt).format("YYYY-MM-DD"),
          }
        : undefined,
    [memberInfoData]
  );

  const sentryUserInfo = memberInfoData ? memberInfoData : null;

  Sentry.getCurrentScope().setUser(sentryUserInfo);

  return {
    isLogin,
    isLoginOut,
    memberInfoData,
    isNormalMember,
    isCorperationMember,
    hackleLoginUserProperties,
  };
};

import { GET_PARTNER_LIST_REQ_QUERY_PARAMS } from "@/domain/partner/constants/partner.constant";
import { EPARTNER_CATEGORY } from "@/domain/partner/enums/partner.enum";
import { useUserInfo } from "@/hooks/useUserInfo.hook";
import { WHOS_ROUTES } from "@/routes/routes.constant";
import { generateAndTrackHackleEvent } from "@/utils/hackle.util";
import { getEncodedQuery } from "@/utils/query.util";
import Link from "next/link";
import styled from "styled-components";

export const Gnb = () => {
  const { hackleLoginUserProperties } = useUserInfo();

  const gnbList = [
    {
      id: "PROJECT",
      label: "Project",
      href: WHOS_ROUTES.PROJECT_ARCHITECTURE_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Project", { ...hackleLoginUserProperties });
      },
      lnbList: [
        {
          id: "ARCHITECTURE",
          label: "Architecture",
          href: WHOS_ROUTES.PROJECT_ARCHITECTURE_URL,
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_ArchitectureProject", {
              ...hackleLoginUserProperties,
            });
          },
        },
        {
          id: "INTERIOR",
          label: "Interior",
          href: WHOS_ROUTES.PROJECT_INTERIOR_URL,
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_InteriorProject", {
              ...hackleLoginUserProperties,
            });
          },
        },
      ],
    },
    {
      id: "EXPERT",
      label: "Expert",
      href: WHOS_ROUTES.EXPERTS_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Expert", { ...hackleLoginUserProperties });
      },
      lnbList: [
        {
          id: "ARCHITECTURE",
          label: "Architecture",
          href: WHOS_ROUTES.EXPERTS_URL,
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Architecture", { ...hackleLoginUserProperties });
          },
        },
        {
          id: "INTERIOR",
          label: "Interior",
          href: {
            pathname: WHOS_ROUTES.EXPERTS_URL,
            query: getEncodedQuery({
              ...GET_PARTNER_LIST_REQ_QUERY_PARAMS,
              category: EPARTNER_CATEGORY.INTERIOR,
            }),
          },
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Interior", { ...hackleLoginUserProperties });
          },
        },
        {
          id: "CONSTRUCTION",
          label: "Construction",
          href: {
            pathname: WHOS_ROUTES.EXPERTS_URL,
            query: getEncodedQuery({
              ...GET_PARTNER_LIST_REQ_QUERY_PARAMS,
              category: EPARTNER_CATEGORY.CONSTRUCTION,
            }),
          },
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Construction", { ...hackleLoginUserProperties });
          },
        },
        {
          id: "PHOTO",
          label: "Photo",
          href: {
            pathname: WHOS_ROUTES.EXPERTS_URL,
            query: getEncodedQuery({
              ...GET_PARTNER_LIST_REQ_QUERY_PARAMS,
              category: EPARTNER_CATEGORY.PHOTO,
            }),
          },
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Photo", { ...hackleLoginUserProperties });
          },
        },
      ],
    },
    {
      id: "MATERIAL",
      label: "Material",
      href: WHOS_ROUTES.MATERIAL_LIST_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Material", { ...hackleLoginUserProperties });
      },
      lnbList: null,
    },
    {
      id: "NEWSLETTER",
      label: "Newsletter",
      href: WHOS_ROUTES.NEWS_LETTER_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Newsletter", { ...hackleLoginUserProperties });
      },
      lnbList: null,
    },
    {
      id: "CONTACT",
      label: "Contact",
      href: WHOS_ROUTES.CONTACT_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Contact", { ...hackleLoginUserProperties });
      },
      lnbList: null,
    },
  ];

  return (
    <GnbWrap className="gnb-wrap">
      <ul className="gnb-item-list">
        {gnbList.map(({ id, href, label, onClick, lnbList }) => (
          <li className="gnb-item" key={id}>
            <Link className="gnb-item-link" href={href} onClick={onClick}>
              {label}
            </Link>
            {lnbList && (
              <ul className="lnb-item-list">
                {lnbList.map(({ id, href, label, onClick }) => (
                  <li className="lnb-item" key={id}>
                    <Link className="lnb-item-link" href={href} onClick={onClick}>
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </GnbWrap>
  );
};

const GnbWrap = styled.nav`
  margin: 0 auto;
  width: 1156px;
  .gnb-item-list {
    display: flex;
    justify-content: center;
    gap: 16px;

    .gnb-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      width: 115px;
      height: 60px;
      text-align: center;

      .gnb-item-link {
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.5px;

        &:hover {
          color: var(--brand-color-main-04-main, #f35935);
        }
      }

      .lnb-item-list {
        display: none;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        background: #fff;
        border-top: 2px solid #f35935;
        opacity: 0;
        z-index: 1;
        animation: lnb-item-list-fade-in 0.15s forwards;

        @keyframes lnb-item-list-fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }

        .lnb-item {
          padding: 12px 21px;

          .lnb-item-link {
            color: var(--Mono-gray_15, #404040);
            font-size: 14px;
            line-height: 18px;
            &:hover {
              color: var(--brand-color-main-04-main, #f35935);
            }
          }
        }
      }
    }

    .gnb-item:hover .lnb-item-list {
      display: block;
    }
  }
`;

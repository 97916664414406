import { useResponsiveBreakpoint } from "@/hooks/useResponsiveBreakpoint.hook";
import { WHOS_ROUTES } from "@/routes/routes.constant";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import styled from "styled-components";
import { MagnifierIcon } from "../icons/MagnifierIcon";
import { ThinCloseIcon } from "../icons/ThinCloseIcon";

type TProps = {
  showSearchWrap: boolean;
  setShowSearchWrap: Dispatch<SetStateAction<boolean>>;
};

export const SearchForm = ({ showSearchWrap, setShowSearchWrap }: TProps) => {
  const router = useRouter();

  const { isMobile, isDesktop } = useResponsiveBreakpoint();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const debounceTimer = useRef<NodeJS.Timeout>();

  const handleButtonClick = () => {
    const inputEl = inputRef.current;
    if (!inputEl) return;

    inputEl.value = inputEl.value.trim();

    if (inputEl.value) {
      router.push({
        pathname: WHOS_ROUTES.SEARCH_URL,
        query: {
          keyword: inputEl.value,
        },
      });
      document.body.scrollTop = 0;
    } else {
      inputEl.focus();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) =>
    event.key === "Enter" && handleButtonClick();

  useEffect(() => {
    clearTimeout(debounceTimer.current);

    if (!showSearchWrap) {
      debounceTimer.current = setTimeout(() => {
        inputRef.current && (inputRef.current.value = "");
      }, 150);
    }
  }, [showSearchWrap]);

  const handleClickCloseIconBtn = () => setShowSearchWrap(false);

  return (
    (isDesktop && (
      <DLayout $showSearchWrap={showSearchWrap}>
        <div className="search-input-wrap">
          <input
            ref={inputRef}
            placeholder="검색어를 입력해주세요"
            onKeyDown={handleKeyPress}
            className="search-input"
          />
          <button onClick={handleButtonClick} className="magnifier-icon-btn">
            <MagnifierIcon viewBoxSize={24} fill="#888" />
          </button>
        </div>
        <button onClick={handleClickCloseIconBtn} className="close-icon-btn">
          <ThinCloseIcon viewBoxSize={30} />
        </button>
      </DLayout>
    )) ||
    (isMobile && (
      <MLayout $showSearchWrap={showSearchWrap}>
        <div className="search-input-wrap">
          <input
            ref={inputRef}
            placeholder="검색어를 입력해주세요"
            onKeyDown={handleKeyPress}
            className="search-input"
          />
          <button onClick={handleButtonClick} className="magnifier-icon-btn">
            <MagnifierIcon viewBoxSize={24} fill="#888" />
          </button>
        </div>
      </MLayout>
    ))
  );
};

const DLayout = styled.div<{ $showSearchWrap: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: ${({ $showSearchWrap }) => ($showSearchWrap ? 1 : 0)};
  z-index: ${({ $showSearchWrap }) => ($showSearchWrap ? "auto" : -1)};
  transition: 0.15s;

  .search-input-wrap {
    position: relative;
    padding: 16px;

    .search-input {
      width: 631px;
      padding: 12px 64px 12px 32px;
      border-radius: 100px;
      border: 1px solid var(--mono-color-10, #888);
      color: var(--Mono-gray_15, #404040);
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.5px;
    }

    .magnifier-icon-btn {
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 48px;
    }
  }

  .close-icon-btn {
    position: absolute;
    top: 49%;
    right: 0;
    transform: translateY(-50%);
  }
`;

const MLayout = styled.div<{ $showSearchWrap: boolean }>`
  height: ${({ $showSearchWrap }) => ($showSearchWrap ? "92px" : "0")};
  transition: 0.15s;
  overflow: hidden;

  .search-input-wrap {
    position: relative;
    padding: 16px;

    .search-input {
      width: 100%;
      padding: 16px 64px 16px 32px;
      border-radius: 100px;
      border: 1px solid var(--mono-color-10, #888);
      color: var(--Mono-gray_15, #404040);
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.2px;
    }

    .magnifier-icon-btn {
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      right: 48px;
    }
  }
`;

export const objectKeys = <T extends Record<PropertyKey, T[keyof T]>>(obj: T): Array<keyof T> => {
  return Object.keys(obj);
};

export const objectValues = <T extends Record<PropertyKey, T[keyof T]>>(
  obj: T
): Array<T[keyof T]> => {
  return Object.values(obj);
};

export const objectEntries = <T extends Record<PropertyKey, T[keyof T]>>(
  obj: T
): Array<[keyof T, T[keyof T]]> => {
  return Object.entries(obj);
};

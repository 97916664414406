import { useEffect } from "react";

type TProps = {
  visible?: boolean;
};

export const useOverflowHiddenOnMount = ({ visible = true }: TProps) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [visible]);
};

export enum EIMAGE_URL {
  CONTACT_PAGE_IMAGE = "CONTACT_PAGE_IMAGE",
  NEWS_LETTER_PAGE_IMAGE = "NEWS_LETTER_PAGE_IMAGE",
  KAKAO_TALK = "KAKAO_TALK",
  QUESTION = "QUESTION",
  OPEN_GRAPH_DEFAULT_IMAGE = "OPEN_GRAPH_DEFAULT_IMAGE",
  ECO_LABEL = "ECO_LABEL",
  EPD = "EPD",
  LOW_CARBON = "LOW_CARBON",
  GR = "GR",
  KS = "KS",
}

import { EEX_URL } from "@/enums/url.enum";

export const EX_URL = {
  [EEX_URL.ENERGYX_URL]: "https://www.energyx.ai",
  [EEX_URL.TOMORROW_URL]: "https://www.tomarch.kr/",
  [EEX_URL.DESIGN_WHOS_INSTAGRAM_URL]: "https://www.instagram.com/designwhos/",
  [EEX_URL.DESIGN_WHOS_BLOG_URL]: "https://blog.naver.com/designwhoswho",
  [EEX_URL.ENERGYX_TERMS_USE_AGREEMENT_URL]:
    "https://member.energyx.ai/terms?fetchfrom=DESIGN_WHOS&tabValue=PRIVACY_USE_AGREEMENT",
  [EEX_URL.ENERGYX_TERMS_USE_POLICY_URL]:
    "https://member.energyx.ai/terms?fetchfrom=DESIGN_WHOS&tabValue=PRIVACY_POLICY",
} as const;

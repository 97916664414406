import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";
import { IMAGE_URL } from "@/constants/imageUrl.constant";
import { useUserInfo } from "@/hooks/useUserInfo.hook";
import { prevRoutePath } from "@/recoil/route.recoil";
import { WHOS_ROUTES, WHOS_ROUTES_PAGE_NAME } from "@/routes/routes.constant";
import { TObjectValueUnion } from "@/types/common.type";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";

type TProps = {
  children: JSX.Element;
  ogTitleProps?: string;
  ogDescriptionProps?: string;
  ogImageProps?: string;
  ogUrlProps?: string;
};

const RootLayout = ({
  children,
  ogTitleProps,
  ogDescriptionProps,
  ogImageProps,
  ogUrlProps,
}: TProps) => {
  const ogTitle = ogTitleProps ?? "디자인후즈, 모든 공간의 시작";
  const ogDescription = ogDescriptionProps ?? "국내의 다양한 건축, 인테리어 프로젝트를 소개합니다.";
  const ogImage = ogImageProps ?? IMAGE_URL.OPEN_GRAPH_DEFAULT_IMAGE;
  const ogUrl = ogUrlProps ?? "https://designwhos.com";

  const router = useRouter();
  useUserInfo();

  const desktopMinWidth = router.pathname.includes("mypage") ? 1220 : 1156;

  const setPrevRoute = useSetRecoilState(prevRoutePath);

  useEffect(() => {
    setPrevRoute(
      WHOS_ROUTES_PAGE_NAME[
        router.pathname.replace("[id]", ":id") as TObjectValueUnion<typeof WHOS_ROUTES>
      ]
    );
  }, [router]);

  return (
    <>
      <Head>
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={ogUrl} />
      </Head>
      <Layout $desktopMinWidth={desktopMinWidth}>
        <Header />
        {children}
        <Footer />
      </Layout>
    </>
  );
};

const Layout = styled.div<{ $desktopMinWidth: number }>`
  display: flex;
  flex-direction: column;
  min-width: ${({ $desktopMinWidth }) => $desktopMinWidth + "px"};
  min-width: 1156px;
  min-height: 100vh;

  @media screen and (max-width: 767px) {
    min-width: 360px;
  }
`;

export default RootLayout;

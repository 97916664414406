import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
};

export const UserIcon = ({ viewBoxSize }: TProps) => {
  const defaultViewBoxSize = 20;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M10 10C12.0719 10 13.75 8.32188 13.75 6.25C13.75 4.17812 12.0719 2.5 10 2.5C7.92812 2.5 6.25 4.17812 6.25 6.25C6.25 8.32188 7.92812 10 10 10ZM10 11.875C7.49687 11.875 2.5 13.1313 2.5 15.625V16.5625C2.5 17.0781 2.92188 17.5 3.4375 17.5H16.5625C17.0781 17.5 17.5 17.0781 17.5 16.5625V15.625C17.5 13.1313 12.5031 11.875 10 11.875Z"
        fill="#404040"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

import { DesktopViewer } from "@/components/box/DesktopViewer";
import { MobileViewer } from "@/components/box/MobileViewer";
import { HamburgerIcon } from "@/components/icons/HamburgerIcon";
import { HomeIcon } from "@/components/icons/HomeIcon";
import { LargeArrowLeftIcon } from "@/components/icons/LargeArrowLeftIcon";
import { MagnifierIcon } from "@/components/icons/MagnifierIcon copy";
import { UserIcon } from "@/components/icons/UserIcon";
import { Gnb } from "@/components/layout/Gnb";
import { Snb } from "@/components/layout/Snb";
import { DesignWhosSquareLogo } from "@/components/logos/DesignWhosSquareLogo";
import { DesignWhosTextLogo } from "@/components/logos/DesignWhosTextLogo";
import { SearchForm } from "@/components/search/SearchForm";
import { HoriziontalDividingLine } from "@/components/style/common.styled-componets";
import { useUserInfo } from "@/hooks/useUserInfo.hook";
import { WHOS_ROUTES, WHOS_ROUTES_PAGE_NAME } from "@/routes/routes.constant";
import { TObjectValueUnion } from "@/types/common.type";
import { generateAndTrackHackleEvent } from "@/utils/hackle.util";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import styled from "styled-components";

const Header = () => {
  const router = useRouter();

  const { isLogin, isLoginOut, hackleLoginUserProperties } = useUserInfo();

  const [showSnb, setShowSnb] = useState(false);
  const [showSearchWrap, setShowSearchWrap] = useState(false);

  const handleClickMagnifierIconBtn = () => {
    setShowSearchWrap((prev) => !prev);
  };

  const handleClickHamburgerIconBtn = () => {
    setShowSnb(true);
    setShowSearchWrap(false);
  };

  const handleClickLoginLink = () => {
    generateAndTrackHackleEvent("click_Header_Login", {
      ...hackleLoginUserProperties,
      Current_Path:
        WHOS_ROUTES_PAGE_NAME[
          router.pathname.replace("[id]", ":id") as TObjectValueUnion<typeof WHOS_ROUTES>
        ],
    });
  };

  const handleClickSignUpLink = () => {
    generateAndTrackHackleEvent("click_Header_Join", {
      ...hackleLoginUserProperties,
      Current_Path:
        WHOS_ROUTES_PAGE_NAME[
          router.pathname.replace("[id]", ":id") as TObjectValueUnion<typeof WHOS_ROUTES>
        ],
    });
  };

  return (
    <Container>
      <DesktopViewer className="d-header-wrap">
        <>
          <div className="header-logo-wrap">
            <button onClick={handleClickMagnifierIconBtn}>
              <MagnifierIcon viewBoxSize={24} />
            </button>
            <Link className="logo-wrap" href={WHOS_ROUTES.MAIN_URL}>
              <DesignWhosSquareLogo viewBoxSize={22} />
              <DesignWhosTextLogo viewBoxHeight={21} />
            </Link>
            <div className="auth-wrap">
              {isLogin && (
                <button
                  className="flex items-center gap-4"
                  onClick={() => router.push(WHOS_ROUTES.MYPAGE_SCRAP_URL)}
                >
                  <UserIcon viewBoxSize={20} />
                  <span className="mypage-text">마이페이지</span>
                </button>
              )}
              {isLoginOut && (
                <>
                  <Link
                    href={process.env.NEXT_PUBLIC_LOGIN_URL!}
                    className="login-link"
                    onClick={handleClickLoginLink}
                  >
                    로그인
                  </Link>
                  <Link
                    href={process.env.NEXT_PUBLIC_SIGNUP_URL!}
                    className="sign-up-link"
                    onClick={handleClickSignUpLink}
                  >
                    회원가입
                  </Link>
                </>
              )}
            </div>
            <SearchForm showSearchWrap={showSearchWrap} setShowSearchWrap={setShowSearchWrap} />
          </div>
          <HoriziontalDividingLine $backgroundColor="#dcdcdc" />
          <Gnb />
          <HoriziontalDividingLine />
        </>
      </DesktopViewer>
      <MobileViewer className="m-header-wrap">
        <>
          <div className="header-main">
            {router.pathname === WHOS_ROUTES.MAIN_URL ? (
              <Link className="logo-wrap" href={WHOS_ROUTES.MAIN_URL}>
                <DesignWhosSquareLogo viewBoxSize={15} />
                <DesignWhosTextLogo viewBoxHeight={15} />
              </Link>
            ) : (
              <button onClick={() => router.back()}>
                <LargeArrowLeftIcon viewBoxSize={24} />
              </button>
            )}
            <div className="header-right-wrap">
              <button onClick={handleClickMagnifierIconBtn}>
                <MagnifierIcon viewBoxSize={24} />
              </button>
              {router.pathname !== WHOS_ROUTES.MAIN_URL && (
                <Link href={WHOS_ROUTES.MAIN_URL}>
                  <HomeIcon viewBoxSize={24} />
                </Link>
              )}
              <button onClick={handleClickHamburgerIconBtn}>
                <HamburgerIcon viewBoxSize={24} />
              </button>
            </div>
          </div>
          <SearchForm showSearchWrap={showSearchWrap} setShowSearchWrap={setShowSearchWrap} />
          <Snb visible={showSnb} setVisible={setShowSnb} />
        </>
      </MobileViewer>
    </Container>
  );
};

const Container = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background: #fff;

  .d-header-wrap .header-logo-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 1156px;
    height: 90px;

    .logo-wrap {
      position: absolute;
      left: 50%;
      top: 50%;
      display: flex;
      align-items: center;
      gap: 9px;
      transform: translate(-50%, -50%);
    }

    .auth-wrap {
      display: flex;
      align-items: center;
      gap: 16px;

      .login-link {
        padding: 8px 16px;
        border-radius: 3px;
        background: var(--black-white-white, #fff);
        color: var(--Mono-gray_15, #404040);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.5px;
      }

      .sign-up-link {
        padding: 8px 16px;
        border-radius: 3px;
        background: var(--Primary-Primary_05, #f35935);
        color: var(--black-white-white, #fff);
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.5px;
      }

      .mypage-text {
        color: var(--Mono-gray_15, #404040);
      }
    }
  }

  .m-header-wrap .header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: 57px;
    border-bottom: 1px solid var(--Mono-gray_03, #dcdcdc);

    .logo-wrap {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    .header-right-wrap {
      display: flex;
      align-items: center;
      gap: 24px;
    }
  }
`;

export default Header;

import styled from "styled-components";

export const HoriziontalDividingLine = styled.div<{ $backgroundColor?: string }>`
  height: 1px;
  background-color: ${({ $backgroundColor }) => ($backgroundColor ? $backgroundColor : "#e4e4e4")};
`;

export const DefaultLayout = styled.section`
  width: 1156px;
  margin: auto;

  @media screen and (max-width: 768px) {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
`;

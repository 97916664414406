import { EWHOS_ROUTES } from "./routes.enum";

export const WHOS_ROUTES = {
  [EWHOS_ROUTES.MAIN_URL]: "/",
  [EWHOS_ROUTES.SEARCH_URL]: "/search",
  [EWHOS_ROUTES.PROJECT_ARCHITECTURE_URL]: "/project/architecture",
  [EWHOS_ROUTES.PROJECT_INTERIOR_URL]: "/project/interior",
  [EWHOS_ROUTES.PROJECT_ARCHITECTURE_DETAIL]: "/project/architecture/detail/:id",
  [EWHOS_ROUTES.PROJECT_INTERIOR_DETAIL]: "/project/interior/detail/:id",
  [EWHOS_ROUTES.EXPERTS_URL]: "/experts",
  [EWHOS_ROUTES.EXPERTS_DETAIL_URL]: "/experts/detail/:id",
  [EWHOS_ROUTES.MATERIAL_LIST_URL]: "/materials/list",
  [EWHOS_ROUTES.MATERIAL_DETAIL_URL]: "/materials/:id",
  [EWHOS_ROUTES.NEWS_LETTER_URL]: "/news-letter",
  [EWHOS_ROUTES.CONTACT_URL]: "/contact",
  [EWHOS_ROUTES.MYPAGE_SETTING_URL]: "/mypage/setting",
  [EWHOS_ROUTES.MYPAGE_SCRAP_URL]: "/mypage/scrap",
} as const;

export const WHOS_ROUTES_PAGE_NAME = {
  [WHOS_ROUTES[EWHOS_ROUTES.MAIN_URL]]: "Main",
  [WHOS_ROUTES[EWHOS_ROUTES.SEARCH_URL]]: "Search Result",
  [WHOS_ROUTES[EWHOS_ROUTES.PROJECT_ARCHITECTURE_URL]]: "Architecture Project",
  [WHOS_ROUTES[EWHOS_ROUTES.PROJECT_INTERIOR_URL]]: "Interior Project",
  [WHOS_ROUTES[EWHOS_ROUTES.PROJECT_ARCHITECTURE_DETAIL]]: "Project Detail",
  [WHOS_ROUTES[EWHOS_ROUTES.PROJECT_INTERIOR_DETAIL]]: "Project Detail",
  [WHOS_ROUTES[EWHOS_ROUTES.EXPERTS_URL]]: "Expert",
  [WHOS_ROUTES[EWHOS_ROUTES.EXPERTS_DETAIL_URL]]: "Expert Detail",
  [WHOS_ROUTES[EWHOS_ROUTES.MATERIAL_LIST_URL]]: "Material",
  [WHOS_ROUTES[EWHOS_ROUTES.MATERIAL_DETAIL_URL]]: "Material Detail",
  [WHOS_ROUTES[EWHOS_ROUTES.NEWS_LETTER_URL]]: "News Letter",
  [WHOS_ROUTES[EWHOS_ROUTES.CONTACT_URL]]: "Contact",
  [WHOS_ROUTES[EWHOS_ROUTES.MYPAGE_SETTING_URL]]: "Mypage Setting",
  [WHOS_ROUTES[EWHOS_ROUTES.MYPAGE_SCRAP_URL]]: "Mypage Scrap",
} as const;

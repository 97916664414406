import { QueryKey } from "@tanstack/react-query";
import dayjs from "dayjs";

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return phoneNumber;
};

// https://section.blog.naver.com/?~~~ -> SectionBlogNaver 로 변환
export const formatSpecificDomainName = (url: string) => {
  const hostnameMatch = url.match(/:\/\/(www\.)?([^\/]+)/);
  const hostname = hostnameMatch && hostnameMatch[2];

  if (hostname) {
    // 도메인을 '.' 기준으로 분리
    let parts = hostname.split(".");
    // 최상위 도메인(TLD)과 공공 도메인(SLD)을 제외
    parts = parts.slice(0, -2).length > 0 ? parts.slice(0, -2) : parts.slice(0, -1);
    // 파스칼 케이스로 변환
    return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join("");
  } else {
    return "";
  }
};

export const isSameQueryKey = (a: QueryKey, b: (string | number)[]) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * 주어진 문자열 형태의 전화번호를 특정 포맷으로 변환합니다.
 * 이 함수는 먼저 입력된 전화번호에서 숫자가 아닌 모든 문자를 제거합니다.
 * 그 다음, 숫자만 남은 문자열을 `xxx-xxxx-xxxx` 형태로 변환합니다.
 * 변환되는 형식은 대한민국의 일반적인 휴대폰 및 일부 지역번호 형식에 맞춰져 있습니다.
 * 이 함수는 주로 폼 입력값의 전화번호를 정규화하는데 사용됩니다.
 *
 * @param {string} phoneNumber - 변환할 전화번호 문자열. 숫자와 기호가 섞여 있을 수 있습니다.
 * @returns {string} - `xxx-xxxx-xxxx` 형태로 변환된 전화번호 문자열.
 *                     입력값이 변환 포맷에 맞지 않을 경우, 원본 문자열을 숫자만 남긴 형태로 반환합니다.
 *
 * @example
 * const formattedNumber = convertToPhoneNumberFormat("01012345678");
 * // formattedNumber는 "010-1234-5678"을 반환합니다.
 */
export const convertToPhoneNumberFormat = (phoneNumber: string) =>
  phoneNumber.replace(/[^0-9]/g, "").replace(/^(\d{3})(\d{4})(\d{4,})$/, "$1-$2-$3");

/**
 * 파라미터가 포함된 경로를 실제 값으로 변환합니다.
 * @param {string} path - 파라미터 자리표시자가 포함된 원본 경로 템플릿.
 * @param {{ [key: string]: string }} params - 파라미터 이름을 키로, 실제 값을 값으로 갖는 객체.
 * @returns {string} 모든 자리표시자가 실제 파라미터 값으로 대체된 경로.
 * @example pathConvertId("/user/:id", { id: "123" }) => "/user/123"
 */
export const pathConvertId = (path: string, params: { [key: string]: string | number }) => {
  return Object.entries(params).reduce((newPath, [paramKey, paramValue]) => {
    return newPath.replace(`:${paramKey}`, paramValue.toString());
  }, path);
};

/**
 * 바이트 단위의 파일 크기를 읽기 쉬운 문자열 형태로 변환합니다.
 * @param {number} size - 변환하고자 하는 파일의 크기(바이트 단위).
 * @returns {string} 변환된 파일 크기와 적절한 단위(KB, MB, GB, TB, PB)를 포함한 문자열.
 * @example convertByteToStorageSize(1024) => "1KB"
 * @example convertByteToStorageSize(1048576) => "1MB"
 * @description
 * 이 함수는 바이트 단위로 제공된 파일 크기를 KB, MB, GB, TB, PB 단위로 변환하여 반환합니다.
 * 변환 과정에서 재귀적으로 크기를 1024로 나누어 가며 적절한 단위를 찾습니다.
 * 최종적으로 변환된 크기는 소수점 첫째 자리에서 반올림
 */
export const convertByteToStorageSize = (size: number) => {
  let recCount = 0;
  const units = ["B", "KB", "MB", "GB", "TB", "PB"];

  const getSize = (newSize: number): number => {
    if (newSize < 1024 && recCount === 0) {
      return newSize / 1024;
    }

    if (newSize / 1024 >= 1) {
      recCount++;
      return getSize(newSize / 1024);
    }

    return newSize;
  };

  const newSize = getSize(size);

  return Math.ceil(newSize * 10) / 10 + units[recCount];
};

/**
 * 주어진 객체에서 옵션 키와 값으로 구성된 새로운 배열을 생성합니다.
 * @param {Record<PropertyKey, T[keyof T]>} obj - 옵션 배열을 생성할 객체.
 * @param {object} [options] - 함수 설정을 위한 선택적 매개변수 객체.
 * @param {keyof any} [options.optionKey="id"] - 반환될 배열의 객체에서 사용할 키.
 * @param {keyof any} [options.optionValue="label"] - 반환될 배열의 객체에서 사용할 값.
 * @returns {{ [P in K | V]: P extends K ? keyof T : T[keyof T] }[]} - 옵션 키와 값으로 구성된 객체의 배열.
 * @example
 * setOptions({ a: "Apple", b: "Banana" }, { optionKey: "key", optionValue: "value" });
 * // => [{ key: "a", value: "Apple" }, { key: "b", value: "Banana" }]
 */
export const setOptions = <
  T extends Record<PropertyKey, T[keyof T]>,
  K extends PropertyKey = "id",
  V extends PropertyKey = "label"
>(
  obj: T,
  {
    optionKey = "id" as K,
    optionValue = "label" as V,
  }: {
    optionKey?: K;
    optionValue?: V;
  } = {}
) => {
  return Object.entries(obj).map(([key, value]) => ({
    [optionKey]: key,
    [optionValue]: value,
  })) as { [P in K | V]: P extends K ? keyof T : T[keyof T] }[];
};

export const isWithinPastNDays = (date: string, dayAfterNum: number) =>
  dayjs(date).isAfter(dayjs().subtract(dayAfterNum, "day"));

/**
 * 깊은 비교를 수행하는 함수
 *
 * @param {any} comparison1 - 비교할 첫 번째 값
 * @param {any} comparison2 - 비교할 두 번째 값
 * @returns {boolean} - 두 값이 깊게 비교하여 동일한지 여부
 */
export const deepComparison = (comparison1: any, comparison2: any): boolean => {
  if (comparison1 === comparison2) return true;

  if (typeof comparison1 !== typeof comparison2) return false;

  if (Array.isArray(comparison1) && Array.isArray(comparison2)) {
    if (comparison1.length !== comparison2.length) return false;

    const sortedComparison1 = [...comparison1].sort();
    const sortedComparison2 = [...comparison2].sort();

    for (let i = 0; i < sortedComparison1.length; i++) {
      if (!deepComparison(sortedComparison1[i], sortedComparison2[i])) {
        return false;
      }
    }
    return true;
  }

  if (typeof comparison1 === "object" && typeof comparison2 === "object") {
    const keys1 = Object.keys(comparison1).sort();
    const keys2 = Object.keys(comparison2).sort();

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!deepComparison(comparison1[key], comparison2[key])) {
        return false;
      }
    }
    return true;
  }

  return false;
};

import { logApiErrorWithSentry } from "@/utils/sentry.util";
import axios from "axios";
import qs from "qs";
const removeEmptyStrings = (obj: Record<PropertyKey, any>) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== "" && v !== null));
};

export const $axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  timeout: 15000,
  withCredentials: true,
  paramsSerializer: (params, a) => {
    const filteredParams = removeEmptyStrings(params);
    return qs.stringify(filteredParams, { arrayFormat: "repeat" });
  },
});

export const $sso_axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_MEMBER_API_URL,
  timeout: 15000,
  withCredentials: true,
  headers: {
    "Cache-Control": "no-store",
  },
});

$axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    logApiErrorWithSentry(err);

    if (err.response?.status === 401 || err.response?.status === 403) {
      window.location.href = `${process.env.NEXT_PUBLIC_LOGOUT_URL}`;
      return;
    }
    if (err.response?.status === 404) {
      if (typeof window === "undefined") throw err;
      window.location.replace(`${process.env.NEXT_PUBLIC_MY_URL}/404`);
      return;
    }
    throw err;
  }
);

$sso_axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    logApiErrorWithSentry(err);

    if (err.response?.status === 401 || err.response?.status === 403) {
      window.location.href = `${process.env.NEXT_PUBLIC_LOGOUT_URL}`;
      return;
    }
    throw err;
  }
);

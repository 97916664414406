import { DesktopViewer } from "@/components/box/DesktopViewer";
import { MobileViewer } from "@/components/box/MobileViewer";
import { ArrowDownIcon } from "@/components/icons/ArrowDownIcon";
import { FAMILY_SITE_ITEM_LIST } from "@/components/layout/footer.helper";
import { DesignWhosSquareLogo } from "@/components/logos/DesignWhosSquareLogo";
import { DesignWhosTextLogo } from "@/components/logos/DesignWhosTextLogo";
import { EnergyxLogo } from "@/components/logos/EnergyxLogo";
import { EX_URL } from "@/constants/url.constant";
import { useOutsideClick } from "@/hooks/useOutsideClick.hook";
import { useResponsiveBreakpoint } from "@/hooks/useResponsiveBreakpoint.hook";
import { useUserInfo } from "@/hooks/useUserInfo.hook";
import { generateHackleEvent, hackleClient } from "@/utils/hackle.util";
import Image from "next/image";
import Link from "next/link";
import { MouseEvent, useRef, useState } from "react";
import styled from "styled-components";
import BLOG from "/public/icons/blog.svg";
import INSTAGRAM from "/public/icons/instagram.svg";

const Footer = () => {
  const { hackleLoginUserProperties } = useUserInfo();

  const [showMore, setShowMore] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const familySiteBtnRef = useRef<HTMLButtonElement>(null);
  const { isDesktop, isMobile } = useResponsiveBreakpoint();

  useOutsideClick(familySiteBtnRef, () => setShowOptions(false));

  const handleChangeSelectBox = (e: MouseEvent<HTMLLIElement>) => {
    const selectedValue = e.currentTarget.getAttribute("value");
    const selectedOption = FAMILY_SITE_ITEM_LIST.find((option) => option.value === selectedValue);
    if (selectedValue === "에너지엑스") {
      hackleClient?.track(
        generateHackleEvent("click_Footer_Familysite_EnergyX", { ...hackleLoginUserProperties })
      );
    } else {
      hackleClient?.track(
        generateHackleEvent("click_Footer_Familysite_tomarch", { ...hackleLoginUserProperties })
      );
    }

    if (selectedOption && selectedOption.url && selectedValue) {
      window.open(selectedOption.url, "_blank");
    }
  };

  const handleClickSns = (sns: string) => {
    if (sns === "instagram") {
      hackleClient?.track(
        generateHackleEvent("click_Footer_Instagram", { ...hackleLoginUserProperties })
      );
    }

    if (sns === "blog") {
      hackleClient?.track(
        generateHackleEvent("click_Footer_Blog", { ...hackleLoginUserProperties })
      );
    }
  };

  return (
    <Container>
      <DesktopViewer className="d-footer-wrap">
        <div className="footer-content-wrap">
          <div className="logo-wrap">
            <div className="flex items-center gap-8">
              <DesignWhosSquareLogo viewBoxSize={26} />
              <DesignWhosTextLogo viewBoxHeight={26} />
            </div>
            <div className="flex items-start gap-7">
              <span className="text-with">with</span>
              <EnergyxLogo viewBoxHeight={16} />
            </div>
          </div>
          <div className="info-wrap">
            <div className="info-wrap-header">
              <Link href={"/inquiry"} className="text-inquiry">
                문의하기
              </Link>
              <button
                onClick={() => window.open(process.env.NEXT_PUBLIC_TERMS_USE_POLICY_URL, "_blank")}
              >
                개인정보처리방침
              </button>
              <button
                onClick={() =>
                  window.open(process.env.NEXT_PUBLIC_TERMS_USE_AGREEMENT_URL, "_blank")
                }
              >
                이용약관
              </button>
            </div>
            <div className="flex items-center mt-16 gap-16 normal-text">
              <span>이메일 : designwhos@energyx.co.kr</span>
              <span>전화 : 02-565-7207</span>
            </div>
            <button className="flex items-center mt-8" onClick={() => setShowMore(!showMore)}>
              <span className="show-more-text">자세히 보기</span>
              <ArrowDownIcon viewBoxSize={24} upsideDown={showMore} />
            </button>
            <ShowMoreInfo $showMore={showMore}>
              <div className="flex items-center gap-10 normal-text">
                <span>법인명: 에너지엑스(주)</span>
                <span>대표자 성명: 박성현, 홍두화</span>
                <span>사업자등록번호: 736-86-01306</span>
              </div>
              <p className="normal-text">주소 : 서울 강남구 강남대로 102 38, 2층</p>
              <p className="normal-text mt-8">Ⓒ EnergyX Inc. all rights reserved.</p>
            </ShowMoreInfo>
          </div>
          <div className="family-site-wrap">
            <button
              ref={(e) => {
                if (isMobile) return;
                (familySiteBtnRef.current as unknown) = e;
              }}
              className="family-site-btn"
              onClick={() => setShowOptions((prev) => !prev)}
            >
              <span className="family-site-text">패밀리 사이트</span>
              <ArrowDownIcon viewBoxSize={24} upsideDown={showOptions} />
              {showOptions && (
                <ul className="family-site-list">
                  {FAMILY_SITE_ITEM_LIST.map((familySiteData) => {
                    return (
                      <li
                        className="family-site"
                        key={familySiteData.name}
                        value={familySiteData.value}
                        onClick={(e) => handleChangeSelectBox(e)}
                      >
                        {familySiteData.value}
                      </li>
                    );
                  })}
                </ul>
              )}
            </button>
            <div className="flex gap-8">
              <a
                href={EX_URL.DESIGN_WHOS_INSTAGRAM_URL}
                target="_blank"
                onClick={() => handleClickSns("instagram")}
              >
                <Image src={INSTAGRAM} width={18} height={18} alt="인스타그램" priority={false} />
              </a>
              <a
                href={EX_URL.DESIGN_WHOS_BLOG_URL}
                target="_blank"
                onClick={() => handleClickSns("blog")}
              >
                <Image src={BLOG} width={18} height={18} alt="블로그" priority={false} />
              </a>
            </div>
          </div>
        </div>
      </DesktopViewer>
      {isMobile && (
        <MobileViewer className="m-footer-wrap">
          <>
            <div className="footer-header">
              <Link href={"/inquiry"} className="text-inquiry">
                문의하기
              </Link>
              <button
                onClick={() => window.open(process.env.NEXT_PUBLIC_TERMS_USE_POLICY_URL, "_blank")}
              >
                개인정보처리방침
              </button>
              <button
                onClick={() =>
                  window.open(process.env.NEXT_PUBLIC_TERMS_USE_AGREEMENT_URL, "_blank")
                }
              >
                이용약관
              </button>
            </div>
            <div className="footer-content-wrap">
              <div className="flex items-center gap-6">
                <DesignWhosSquareLogo viewBoxSize={18} />
                <DesignWhosTextLogo viewBoxHeight={18} />
              </div>
              <p className="mt-32 normal-text">전화 : 02-565-7207</p>
              <p className="mt-4 mb-24 normal-text">이메일 : designwhos@energyx.co.kr</p>
              <button className="flex items-center" onClick={() => setShowMore(!showMore)}>
                <span className="show-more-text">자세히 보기</span>
                <ArrowDownIcon viewBoxSize={24} upsideDown={showMore} />
              </button>
              <ShowMoreInfo className="more-info-wrap" $showMore={showMore}>
                <div className="flex items-center gap-16 normal-text">
                  <span>법인명: 에너지엑스(주)</span>
                  <span>대표자 성명: 박성현, 홍두화</span>
                </div>
                <p className="normal-text">사업자등록번호 : 736-86-01306</p>
                <p className="normal-text">주소 : 서울 강남구 강남대로 102 38, 2층</p>
              </ShowMoreInfo>
              <div className="flex items-center mt-16 gap-8">
                <a href={EX_URL.DESIGN_WHOS_INSTAGRAM_URL} target="_blank">
                  <Image src={INSTAGRAM} width={18} height={18} alt="인스타그램" priority={false} />
                </a>
                <a href={EX_URL.DESIGN_WHOS_BLOG_URL} target="_blank">
                  <Image src={BLOG} width={18} height={18} alt="블로그" priority={false} />
                </a>
              </div>
              <button
                ref={(e) => {
                  if (isDesktop) return;
                  (familySiteBtnRef.current as unknown) = e;
                }}
                className="family-site-btn"
                onClick={() => setShowOptions((prev) => !prev)}
              >
                <span className="family-site-text">패밀리 사이트</span>
                <ArrowDownIcon viewBoxSize={24} upsideDown={!showOptions} />
                {showOptions && (
                  <ul className="family-site-list">
                    {FAMILY_SITE_ITEM_LIST.map((familySiteData) => {
                      return (
                        <li
                          className="family-site"
                          key={familySiteData.name}
                          value={familySiteData.value}
                          onClick={(e) => handleChangeSelectBox(e)}
                        >
                          {familySiteData.value}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </button>
              <p className="corporation-text mt-24">Ⓒ EnergyX Inc. all rights reserved.</p>
            </div>
          </>
        </MobileViewer>
      )}
    </Container>
  );
};

const Container = styled.footer`
  margin-top: auto;

  .d-footer-wrap {
    padding: 49px 0;
    border-top: 1px solid var(--Mono-gray_03, #dcdcdc);

    .footer-content-wrap {
      display: flex;
      margin: 0 auto;
      width: 1156px;

      .logo-wrap {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .text-with {
          color: var(--Mono-gray_13, #606060);
          font-size: 17px;
          line-height: 20px;
        }
      }

      .info-wrap {
        margin-left: 129px;

        .info-wrap-header {
          display: flex;
          align-items: center;
          gap: 24px;

          .text-inquiry {
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            transition: 0.1s;
            &:hover {
              color: var(--brand-color-main-04-main, #f35935);
            }
          }

          button {
            font-size: 12px;
            line-height: 18px;
          }
        }

        .normal-text {
          color: var(--Mono-gray_15, #404040);
          font-size: 12px;
          line-height: 18px;
        }

        .show-more-text {
          color: var(--black-white-black, var(--mono-color_18, #000));
          font-size: 12px;
          font-weight: 700;
          line-height: 14px;
        }
      }

      .family-site-wrap {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        gap: 32px;

        .family-site-btn {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 2px 2px 4px 8px;
          width: 142px;
          border-bottom: 1px solid var(--Mono-gray_10, #888);

          .family-site-text {
            color: var(--Mono-gray_15, #404040);
            font-size: 12px;
            line-height: 18px;
          }

          .family-site-list {
            position: absolute;
            list-style: none;
            left: 0;
            top: 31px;
            width: 100%;
            background: #fff;
            border: 1px solid var(--Mono-gray_03, #dcdcdc);
            animation: 0.2s family-site-options-fade-in;

            @keyframes family-site-options-fade-in {
              from {
                top: calc(31px - 4px);
                opacity: 0;
              }
              to {
                top: 31px;
                opacity: 1;
              }
            }

            .family-site {
              padding: 8px;
              text-align: start;
              color: var(--Mono-gray_15, #404040);
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  .m-footer-wrap {
    padding-top: 64px;

    .footer-header {
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 24px;
      border-bottom: 1px solid var(--Mono-gray_03, #dcdcdc);

      .text-inquiry {
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
      }

      button {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .footer-content-wrap {
      padding: 16px 16px 16px 16px;

      .normal-text {
        color: var(--Mono-gray_15, #404040);
        font-size: 12px;
        line-height: 18px;
      }

      .show-more-text {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
      }

      .more-info-wrap {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .family-site-btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        padding: 8px;
        width: 100%;
        border: 1px solid var(--Mono-gray_10, #888);

        .family-site-text {
          color: var(--Mono-gray_15, #404040);
          font-size: 12px;
          line-height: 18px;
        }

        .family-site-list {
          position: absolute;
          list-style: none;
          left: -1px;
          bottom: 41px;
          width: calc(100% + 2px);
          background: #fff;
          border: 1px solid var(--Mono-gray_03, #dcdcdc);
          animation: 0.15s m-family-site-options-fade-in;

          @keyframes m-family-site-options-fade-in {
            from {
              bottom: calc(41px - 4px);
              opacity: 0;
            }
            to {
              bottom: 41px;
              opacity: 1;
            }
          }

          .family-site {
            padding: 8px;
            text-align: start;
            color: var(--Mono-gray_15, #404040);
            font-size: 14px;
            line-height: 18px;

            &:not(:first-of-type) {
              border-top: 1px solid var(--Mono-gray_03, #dcdcdc);
            }
          }
        }
      }

      .corporation-text {
        color: var(--Mono-gray_10, #888);
        font-size: 12px;
        line-height: normal;
      }
    }
  }
`;

const ShowMoreInfo = styled.div<{ $showMore: boolean }>`
  height: ${({ $showMore }) => ($showMore ? "62px" : "0")};
  transition: 0.2s;
  overflow: hidden;
`;

export default Footer;

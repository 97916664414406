import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
  fill?: string;
};

export const MagnifierIcon = ({ viewBoxSize, fill = "#404040" }: TProps) => {
  const defaultViewBoxSize = 24;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46143 4.55013C9.33827 4.18694 10.2781 4 11.2271 4C12.1762 4 13.116 4.18694 13.9928 4.55013C14.8697 4.91333 15.6664 5.44568 16.3375 6.11678C17.0086 6.78788 17.541 7.5846 17.9042 8.46143C18.2673 9.33827 18.4543 10.2781 18.4543 11.2271C18.4543 12.1762 18.2673 13.116 17.9041 13.9928C17.6749 14.5463 17.3782 15.0679 17.0218 15.5461L19.6944 18.2187C20.1019 18.6262 20.1019 19.2869 19.6944 19.6944C19.2869 20.1019 18.6262 20.1019 18.2187 19.6944L15.5461 17.0218C14.3057 17.9464 12.7926 18.4543 11.2271 18.4543C9.31038 18.4543 7.47213 17.6929 6.11678 16.3375C4.76143 14.9822 4 13.1439 4 11.2271C4 9.31038 4.76143 7.47213 6.11678 6.11678C6.78788 5.44568 7.5846 4.91333 8.46143 4.55013ZM11.2271 6.08696C10.5521 6.08696 9.88371 6.21991 9.26008 6.47823C8.63644 6.73655 8.06979 7.11517 7.59248 7.59248C6.62851 8.55645 6.08696 9.86388 6.08696 11.2271C6.08696 12.5904 6.62851 13.8978 7.59248 14.8618C8.55645 15.8258 9.86388 16.3673 11.2271 16.3673C12.5904 16.3673 13.8978 15.8258 14.8618 14.8618C15.3391 14.3845 15.7177 13.8178 15.9761 13.1942C16.2344 12.5706 16.3673 11.9022 16.3673 11.2271C16.3673 10.5521 16.2344 9.88371 15.9761 9.26008C15.7177 8.63644 15.3391 8.06979 14.8618 7.59248C14.3845 7.11517 13.8178 6.73655 13.1942 6.47823C12.5706 6.21991 11.9022 6.08696 11.2271 6.08696Z"
        fill={fill}
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

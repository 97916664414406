import {
  getAuth,
  getCorporationMemberInfo,
  getMemberInfo,
  getNormalMemberInfo,
} from "@/domain/member/api/member.api";
import { useQuery } from "@tanstack/react-query";

export const useMemberAuth = () => {
  return useQuery({
    queryKey: ["memberAuth"],
    queryFn: () => getAuth(),
  });
};

export const useMemberInfo = (auth?: boolean) => {
  return useQuery({
    queryKey: ["memberInfo"],
    queryFn: () => getMemberInfo(),
    enabled: auth,
  });
};

export const useNormalMemberInfo = () => {
  return useQuery({
    queryKey: ["normalMemberInfo"],
    queryFn: () => getNormalMemberInfo(),
  });
};

export const useCorporationMemberInfo = () => {
  return useQuery({
    queryKey: ["corporationMemberInfo"],
    queryFn: () => getCorporationMemberInfo(),
  });
};

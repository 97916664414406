import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
};

export const HamburgerIcon = ({ viewBoxSize }: TProps) => {
  const defaultViewBoxSize = 24;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M4 18.5H20.8V16.1H4V18.5ZM4 6.5V8.9H20.8V6.5H4ZM4 13.7H20.8V11.3H4V13.7Z"
        fill="#404040"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
  upsideDown?: boolean;
  fill?: string;
};

export const LargeArrowLeftIcon = ({ viewBoxSize, upsideDown, fill = "black" }: TProps) => {
  const defaultViewBoxSize = 24;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
      $upsideDown={!!upsideDown}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.59283 12.4991L17.0625 5.02941L16.0018 3.96875L7.47151 12.4991L16.0018 21.0294L17.0625 19.9688L9.59283 12.4991Z"
        fill={fill}
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number; $upsideDown: boolean }>`
  transform: ${({ $upsideDown }) => $upsideDown && "rotate(180deg)"};
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
  fill?: string;
};

export const CloseIcon = ({ viewBoxSize, fill = "#fff" }: TProps) => {
  const defaultViewBoxSize = 20;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M16.6654 4.50065L15.4987 3.33398L9.9987 8.83398L4.4987 3.33398L3.33203 4.50065L8.83203 10.0007L3.33203 15.5007L4.4987 16.6673L9.9987 11.1673L15.4987 16.6673L16.6654 15.5007L11.1654 10.0007L16.6654 4.50065Z"
        fill={fill}
      />
      ;
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

import { GET_PARTNER_LIST_REQ_QUERY_PARAMS } from "@/domain/partner/constants/partner.constant";
import { EPARTNER_CATEGORY } from "@/domain/partner/enums/partner.enum";
import { useOverflowHiddenOnMount } from "@/hooks/useOverflowHiddenOnMount";
import { useUserInfo } from "@/hooks/useUserInfo.hook";
import { WHOS_ROUTES, WHOS_ROUTES_PAGE_NAME } from "@/routes/routes.constant";
import { TObjectValueUnion } from "@/types/common.type";
import { generateAndTrackHackleEvent } from "@/utils/hackle.util";
import { getEncodedQuery } from "@/utils/query.util";
import Link from "next/link";
import { useRouter } from "next/router";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CloseIcon } from "../icons/CloseIcon";
import { LargeArrowLeftIcon } from "../icons/LargeArrowLeftIcon";
import { UserIcon } from "../icons/UserIcon";

type TProps = {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
};

export const Snb = ({ visible, setVisible }: TProps) => {
  const router = useRouter();
  useOverflowHiddenOnMount({ visible });
  const [exist, setExist] = useState(false);
  const debounceTimer = useRef<NodeJS.Timeout>();
  const { isLoginOut, memberInfoData, isNormalMember, hackleLoginUserProperties } = useUserInfo();

  const initSnbList = [
    {
      id: "PROJECT",
      label: "Project",
      expand: false,
      subSnbList: [
        {
          id: "ARCHITECTURE",
          label: "Architecture",
          href: WHOS_ROUTES.PROJECT_ARCHITECTURE_URL,
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_ArchitectureProject", {
              ...hackleLoginUserProperties,
            });
            setVisible(false);
          },
        },
        {
          id: "INTERIOR",
          label: "Interior",
          href: WHOS_ROUTES.PROJECT_INTERIOR_URL,
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_InteriorProject", {
              ...hackleLoginUserProperties,
            });
            setVisible(false);
          },
        },
      ],
    },
    {
      id: "EXPERT",
      label: "Expert",
      expand: false,
      subSnbList: [
        {
          id: "ARCHITECTURE",
          label: "Architecture",
          href: WHOS_ROUTES.EXPERTS_URL,
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Architecture", { ...hackleLoginUserProperties });
            setVisible(false);
          },
        },
        {
          id: "INTERIOR",
          label: "Interior",
          href: {
            pathname: WHOS_ROUTES.EXPERTS_URL,
            query: getEncodedQuery({
              ...GET_PARTNER_LIST_REQ_QUERY_PARAMS,
              category: EPARTNER_CATEGORY.INTERIOR,
            }),
          },
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Interior", { ...hackleLoginUserProperties });
            setVisible(false);
          },
        },
        {
          id: "CONSTRUCTION",
          label: "Construction",
          href: {
            pathname: WHOS_ROUTES.EXPERTS_URL,
            query: getEncodedQuery({
              ...GET_PARTNER_LIST_REQ_QUERY_PARAMS,
              category: EPARTNER_CATEGORY.CONSTRUCTION,
            }),
          },
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Construction", { ...hackleLoginUserProperties });
            setVisible(false);
          },
        },
        {
          id: "PHOTO",
          label: "Photo",
          href: {
            pathname: WHOS_ROUTES.EXPERTS_URL,
            query: getEncodedQuery({
              ...GET_PARTNER_LIST_REQ_QUERY_PARAMS,
              category: EPARTNER_CATEGORY.PHOTO,
            }),
          },
          onClick: () => {
            generateAndTrackHackleEvent("click_LNB_Photo", { ...hackleLoginUserProperties });
            setVisible(false);
          },
        },
      ],
    },
    {
      id: "MATERIAL",
      label: "Material",
      href: WHOS_ROUTES.MATERIAL_LIST_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Material", { ...hackleLoginUserProperties });
        setVisible(false);
      },
      subSnbList: null,
    },
    {
      id: "NEWSLETTER",
      label: "Newsletter",
      href: WHOS_ROUTES.NEWS_LETTER_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Newsletter", { ...hackleLoginUserProperties });
        setVisible(false);
      },
      subSnbList: null,
    },
    {
      id: "CONTACT",
      label: "Contact",
      href: WHOS_ROUTES.CONTACT_URL,
      onClick: () => {
        generateAndTrackHackleEvent("click_GNB_Contact", { ...hackleLoginUserProperties });
        setVisible(false);
      },
      subSnbList: null,
    },
  ];
  const [snbList, setSnbList] = useState(initSnbList);

  const handleClickCloseIconBtn = () => setVisible(false);

  const handleClickSnbItemBtn = (id: string) => {
    const _snbList = [...snbList];
    const index = _snbList.findIndex((snbItem) => snbItem.id === id);
    _snbList[index].expand = !_snbList[index].expand;
    setSnbList(_snbList);
  };

  const handleClickUserIconLink = () => setVisible(false);

  useEffect(() => {
    clearTimeout(debounceTimer.current);

    if (visible) {
      setExist(true);
    } else {
      debounceTimer.current = setTimeout(() => {
        setExist(false);
        setSnbList(initSnbList);
      }, 300);
    }
  }, [visible]);

  return (
    <>
      {exist && (
        <Layout $visible={visible}>
          <div className="snb-popup">
            <div className="snb-overlay" />
            <div className="snb-container">
              <div className="close-icon-btn-wrap">
                <button className="close-icon-btn" onClick={handleClickCloseIconBtn}>
                  <CloseIcon viewBoxSize={20} />
                </button>
              </div>
              <div className="snb-wrap">
                <div className="auth-wrap">
                  {isLoginOut ? (
                    <>
                      <Link
                        href={`${process.env.NEXT_PUBLIC_LOGIN_URL}?callbackUrl=${window.location.href}`}
                        className="login-btn"
                        onClick={() => {
                          generateAndTrackHackleEvent("click_Header_Login", {
                            ...hackleLoginUserProperties,
                            Current_Path:
                              WHOS_ROUTES_PAGE_NAME[
                                router.pathname.replace("[id]", ":id") as TObjectValueUnion<
                                  typeof WHOS_ROUTES
                                >
                              ],
                          });
                        }}
                      >
                        로그인
                      </Link>
                      <div className="flex items-center justify-between">
                        <span className="sign-up-info">1분 만에 회원이 되어보세요.</span>
                        <Link
                          href={`${process.env.NEXT_PUBLIC_SIGNUP_URL}?callbackUrl=${window.location.href}`}
                          className="sign-up-btn"
                          onClick={() => {
                            generateAndTrackHackleEvent("click_Header_Join", {
                              ...hackleLoginUserProperties,
                              Current_Path:
                                WHOS_ROUTES_PAGE_NAME[
                                  router.pathname.replace("[id]", ":id") as TObjectValueUnion<
                                    typeof WHOS_ROUTES
                                  >
                                ],
                            });
                          }}
                        >
                          회원가입
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center justify-between">
                      <Link
                        href={WHOS_ROUTES.MYPAGE_SCRAP_URL}
                        className="user-icon-btn"
                        onClick={handleClickUserIconLink}
                      >
                        <UserIcon viewBoxSize={20} />
                        <span className="user-icon-label">
                          {isNormalMember ? memberInfoData?.phoneNumber : memberInfoData?.email}
                        </span>
                      </Link>
                      <Link href={process.env.NEXT_PUBLIC_LOGOUT_URL!} className="logout-btn">
                        로그아웃
                      </Link>
                    </div>
                  )}
                </div>
                <ul className="snb-item-list">
                  {snbList.map(({ id, label, subSnbList, expand, href, onClick }) => (
                    <li className="snb-item" key={id}>
                      {subSnbList ? (
                        <button className="snb-item-btn" onClick={() => handleClickSnbItemBtn(id)}>
                          <span className="snb-item-label">{label}</span>
                          <LargeArrowLeftIconWrap $expand={expand}>
                            <LargeArrowLeftIcon viewBoxSize={20} upsideDown fill="#404040" />
                          </LargeArrowLeftIconWrap>
                        </button>
                      ) : (
                        <Link href={href} className="snb-item-btn" onClick={onClick}>
                          <span className="snb-item-label">{label}</span>
                        </Link>
                      )}
                      {subSnbList && (
                        <SnbSubItemList $expand={expand}>
                          {subSnbList.map(({ id, label, href, onClick }) => (
                            <li className="snb-sub-item" key={id}>
                              <Link className="snb-sub-item-btn" href={href} onClick={onClick}>
                                {label}
                              </Link>
                            </li>
                          ))}
                        </SnbSubItemList>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

const Layout = styled.nav<{ $visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .snb-popup {
    position: relative;
    width: 100%;
    height: 100%;

    .snb-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      animation: ${({ $visible }) =>
        $visible ? "snb-overlay-fade-in 0.3s forwards" : "snb-overlay-fade-out 0.3s forwards"};
      opacity: 0;

      @keyframes snb-overlay-fade-in {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }

      @keyframes snb-overlay-fade-out {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }

    .snb-container {
      position: absolute;
      top: 0;
      left: 100%;
      display: flex;
      align-items: start;
      width: 100%;
      height: 100%;
      animation: ${({ $visible }) =>
        $visible ? "sub-container-fade-in 0.3s forwards" : "sub-container-fade-out 0.3s forwards"};

      @keyframes sub-container-fade-in {
        from {
          left: 100%;
        }
        to {
          left: 0;
        }
      }

      @keyframes sub-container-fade-out {
        from {
          left: 0;
        }
        to {
          left: 100%;
        }
      }

      .close-icon-btn-wrap {
        display: flex;
        justify-content: center;
        width: 54px;

        .close-icon-btn {
          margin-top: 40px;
        }
      }

      .snb-wrap {
        flex: 1 0 0;
        height: 100%;
        background: #fff;
        overflow-y: auto;

        .auth-wrap {
          position: sticky;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          padding: 32px 16px 16px 16px;
          gap: 12px;
          background: #fff;
          border-bottom: 1px solid var(--Mono-gray_03, #dcdcdc);

          .login-btn {
            padding: 8px 16px;
            border-radius: 3px;
            background: var(--black-white-black, #000);
            color: var(--black-white-white, #fff);
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.5px;
          }

          .sign-up-info {
            color: #888888;
            font-size: 14px;
            line-height: 18px;
          }

          .sign-up-btn {
            padding: 6px 16px;
            border-radius: 3px;
            color: var(--Primary-Primary_05, var(--brand-color-main-04-main, #f35935));
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.2px;
            white-space: nowrap;
          }

          .user-icon-btn {
            display: flex;
            align-items: end;
            padding: 8px 16px 8px 12px;
            gap: 4px;

            .user-icon-label {
              font-size: 14px;
              line-height: 18px;
            }
          }

          .logout-btn {
            padding: 8px 16px;
            color: var(--Mono-gray_13, #606060);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: -0.5px;
          }
        }

        .snb-item-list {
          display: flex;
          flex-direction: column;
          padding: 16px;
          gap: 8px;

          .snb-item {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .snb-item-btn {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 16px;
              width: 100%;
              gap: 8px;

              .snb-item-label {
                color: var(--Mono-gray_15, #404040);
                font-weight: 700;
                line-height: 24px;
                letter-spacing: -0.5px;
                font-size: 16px;
                font-family: "Pretendard", sans-serif;
              }
            }
          }
        }
      }
    }
  }
`;

const SnbSubItemList = styled.ul<{ $expand: boolean }>`
  border-radius: 5px;
  background: var(--Mono-gray_01, #fafafa);

  .snb-sub-item {
    height: ${({ $expand }) => ($expand ? "50px" : "0")};
    transition: 0.3s;
    overflow: hidden;
    .snb-sub-item-btn {
      display: block;
      padding: 16px;
      color: var(--Mono-gray_15, #404040);
      font-size: 14px;
      line-height: 18px;
      text-align: start;
    }
  }
`;

const LargeArrowLeftIconWrap = styled.span<{ $expand: boolean }>`
  transform: ${({ $expand }) => ($expand ? "rotate(90deg)" : "rotate(0)")};
  transition: 0.3s;
`;

import { objectEntries } from "./object.util";

export const encodeBase64 = (data: any): string => {
  const jsonStr = JSON.stringify(data);
  return Buffer.from(jsonStr).toString("base64");
};

export const decodeBase64 = (str: string): any => {
  try {
    const jsonStr = Buffer.from(str, "base64").toString("utf-8");
    return JSON.parse(jsonStr);
  } catch (error) {
    return str;
  }
};

export const getEncodedQuery = <T extends Record<PropertyKey, any>>(newQueryParams: T) => {
  const encodedQuery = {} as Record<keyof T, string>;
  objectEntries(newQueryParams).forEach(
    ([key, value]) => (encodedQuery[key] = encodeBase64(value))
  );
  return encodedQuery;
};

export const getDecodedQuery = <T extends Record<PropertyKey, any>>(
  routerQuery: Record<keyof T, string | string[] | undefined>
): T => {
  const decodedQuery = {} as T;
  objectEntries(routerQuery).forEach(([key, value]) => {
    if (typeof value === "string") {
      decodedQuery[key] = decodeBase64(value);
    } else if (Array.isArray(value) && value.length > 0) {
      decodedQuery[key] = decodeBase64(value[0]);
    }
  });

  return decodedQuery;
};

export const convertAsPathToQueryParams = (
  asPath: string,
  queryParamsEnableKeys: string[]
): Record<PropertyKey, any> => {
  const queryStart = asPath.indexOf("?");

  if (queryStart === -1 || queryStart === asPath.length - 1) {
    return {};
  }

  const queryString = asPath.substring(queryStart + 1);
  const pairs = queryString.split("&");
  const queryObject: Record<PropertyKey, any> = {};

  pairs.forEach((pair) => {
    const [key, value] = pair.split("=");
    if (key && value && queryParamsEnableKeys.includes(key)) {
      try {
        const decodedKey = decodeURIComponent(key);
        const decodedValue = decodeURIComponent(value);
        queryObject[decodedKey] = decodedValue;
      } catch (e) {}
    }
  });

  return queryObject;
};

export const getQueryParamKeys = (asPath: string): string[] => {
  const queryParamsString = asPath.split("?")[1];
  if (!queryParamsString) return [];

  return queryParamsString.split("&").map((param) => param.split("=")[0]);
};

import classNames from "classnames";
import styled from "styled-components";

type TProps = {
  children: JSX.Element;
  className?: string;
};

export const MobileViewer = ({ children, className }: TProps) => {
  return <View className={classNames(className)}>{children}</View>;
};

const View = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

import styled from "styled-components";

type TProps = {
  viewBoxHeight: number;
};

export const EnergyxLogo = ({ viewBoxHeight }: TProps) => {
  const defaultViewBoxWidth = 114;
  const defaultViewBoxHeight = 17;
  const viewBoxWidth = (viewBoxHeight / defaultViewBoxHeight) * defaultViewBoxWidth;
  const ratio = viewBoxHeight / defaultViewBoxHeight;
  return (
    <Logo
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxWidth}
      height={viewBoxHeight}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M12.2957 13.0895V16.4594H0V0.984375H11.9828V4.35428H4.27179V6.98473H11.0441V10.2474H4.27179V13.0895H12.2957Z"
        fill="black"
      />
      <path
        d="M29.4929 0.984375V16.4594H25.9503L19.178 8.24915V16.4594H14.9062V0.984375H18.4488L25.2211 9.19464V0.984375H29.4929Z"
        fill="black"
      />
      <path
        d="M45.1238 13.0895V16.4594H32.8281V0.984375H44.8109V4.35428H37.0999V6.98473H43.8722V10.2474H37.0999V13.0895H45.1238Z"
        fill="black"
      />
      <path
        d="M54.4001 12.3529H52.003V16.4594H47.625V0.984375H54.6068C55.8585 0.984375 57.1073 1.19605 58.2528 1.72101C60.2336 2.56208 61.4825 4.56314 61.3791 6.66862C61.3791 7.72137 61.1696 8.66968 60.6499 9.61517C60.1302 10.4562 59.2949 11.1929 58.4623 11.6162L61.7982 16.4594H57.1101L54.4001 12.3529ZM56.4843 4.98367C55.8585 4.56314 55.2326 4.35146 54.5034 4.35146H52.1063V8.87854H54.5034C55.2326 8.98296 55.9618 8.77411 56.4843 8.24633C56.9006 7.82579 57.2135 7.19358 57.2135 6.56137C57.2135 6.03641 56.9006 5.4042 56.4843 4.98367Z"
        fill="black"
      />
      <path
        d="M74.403 8.34933H78.1552V14.7702C77.2164 15.4024 76.1743 15.9274 75.1322 16.2435C73.9867 16.5596 72.8385 16.7684 71.693 16.7684C70.2346 16.7684 68.6701 16.4523 67.315 15.7157C66.0634 15.0835 65.0213 14.0308 64.2921 12.8736C63.5629 11.6092 63.25 10.1359 63.25 8.76704C63.25 7.29376 63.5629 5.92491 64.2921 4.66049C65.0213 3.50332 66.0634 2.45058 67.315 1.81837C68.6701 1.08173 70.2318 0.765625 71.7964 0.765625C73.1514 0.765625 74.4002 0.977303 75.6519 1.50226C76.7973 1.9228 77.7361 2.65943 78.4653 3.60775L75.6519 6.13377C74.7131 5.08102 73.3581 4.44881 71.8997 4.44881C70.7543 4.44881 69.606 4.86935 68.7734 5.71323C67.9409 6.5543 67.5218 7.71429 67.5218 8.87146C67.5218 9.71253 67.7313 10.4492 68.0414 11.1886C68.3544 11.8208 68.874 12.3458 69.6032 12.7663C70.3324 13.1869 71.0616 13.2913 71.8969 13.2913C72.7323 13.2913 73.4587 13.0796 74.1907 12.7663L74.4002 8.34368L74.403 8.34933Z"
        fill="black"
      />
      <path
        d="M89.4096 10.8796V16.4594H85.0317V10.8796L79.1953 0.984375H83.78L87.426 7.08916L91.0719 0.984375H95.2404L89.404 10.8796H89.4096Z"
        fill="black"
      />
      <path
        d="M103.68 3.8265L101.492 0.984375H95.9688L100.866 7.19358L103.68 3.8265Z"
        fill="#7A37D8"
      />
      <path
        d="M107.647 8.458L113.69 0.984375H108.792L95.6641 16.4566H101.291L104.833 11.6162L108.376 16.4566H114.003L107.647 8.458Z"
        fill="black"
      />
    </Logo>
  );
};

const Logo = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

import styled from "styled-components";

type TProps = {
  viewBoxSize: number;
};

export const ThinCloseIcon = ({ viewBoxSize }: TProps) => {
  const defaultViewBoxSize = 30;
  const ratio = viewBoxSize / defaultViewBoxSize;

  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      $ratio={ratio}
    >
      <path
        d="M30.5 1L0.5 31M0.5 1L30.5 31"
        stroke="#404040"
        strokeWidth="0.7"
        strokeLinecap="round"
      />
      ;
    </StyledSvg>
  );
};

const StyledSvg = styled.svg<{ $ratio: number }>`
  path {
    transform: ${({ $ratio }) => `scale(${$ratio})`};
  }
`;

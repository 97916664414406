import RootLayout from "@/layouts/RootLayout";
import "@/styles/common/animation.scss";
import "@/styles/common/custom.scss";
import "@/styles/common/flex.scss";
import "@/styles/common/margin-padding.scss";
import "@/styles/common/quill-editor.scss";
import "@/styles/common/reset.scss";
import "@/styles/common/width-height.scss";
import { hackleClient } from "@/utils/hackle.util";
import { HackleProvider } from "@hackler/react-sdk";
import {
  HydrationBoundary,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import { MouseEvent, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { RecoilRoot } from "recoil";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

declare global {
  interface Window {
    Kakao: any;
  }
}

const queryCache = new QueryCache({
  onError: () => toast.error("데이터 조회에 실패했습니다."),
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      throwOnError: () => true,
    },
  },
  queryCache,
});

export default function App({ Component, pageProps }: Partial<AppProps> = {}) {
  const kakaoInit = () => {
    if (typeof window !== "undefined" && window.Kakao && !window.Kakao.isInitialized()) {
      window.Kakao.init(process.env.NEXT_PUBLIC_KAKAO_KEY);
    }
  };

  useEffect(() => {
    const handleContextmenu = (e: Event | MouseEvent) => {
      e.preventDefault();
    };
    document.addEventListener("contextmenu", handleContextmenu);
    return function cleanup() {
      document.removeEventListener("contextmenu", handleContextmenu);
    };
  }, []);

  useEffect(() => {
    if ("scrollRestoration" in history) {
      history.scrollRestoration = "manual";
    }
  }, []);

  if (!Component || !pageProps) return null;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover, user-scalable=no"
        />
        <title>디자인후즈(Designwhos) | 모든 공간의 시작</title>
        <meta
          name="description"
          content="국내의 다양한 건축, 인테리어 프로젝트와 전문가를 소개합니다."
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Design Whos" />
        <meta property="fb:app_id" content="1071941390804166" />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=G-QL90M46ZBX`}
      />
      <Script
        id="ga4_init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-QL90M46ZBX', {
            page_path: window.location.pathname
          });
        `,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <ReactQueryDevtools initialIsOpen={false} />
          <RecoilRoot>
            <Toaster
              position="top-center"
              toastOptions={{
                style: { padding: "16px", color: "#000000" },
                duration: 2000,
                error: {
                  style: {
                    background: "#FF513E",
                  },
                },
              }}
            />
            {hackleClient ? (
              <HackleProvider hackleClient={hackleClient} supportSSR>
                <RootLayout {...pageProps.rootLayoutProps}>
                  {<Component {...pageProps} />}
                </RootLayout>
              </HackleProvider>
            ) : (
              <RootLayout {...pageProps.rootLayoutProps}>
                <Component {...pageProps} />
              </RootLayout>
            )}
            <Script src="https://developers.kakao.com/sdk/js/kakao.js" onLoad={kakaoInit} />
          </RecoilRoot>
        </HydrationBoundary>
      </QueryClientProvider>
    </>
  );
}

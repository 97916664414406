export enum EPARTNER_CATEGORY {
  ARCHITECTURE = "ARCHITECTURE",
  INTERIOR = "INTERIOR",
  CONSTRUCTION = "CONSTRUCTION",
  PHOTO = "PHOTO",
}

export enum EPARTNER_SORT {
  NAME_ASC = "NAME_ASC", // 이름순
  HITS_DESC = "HITS_DESC", // 인기순
  PROJECTS_DESC = "PROJECTS_DESC", // 프로젝트순
}

import { EIMAGE_URL } from "@/enums/imageUrl.enum";

export const IMAGE_URL = {
  [EIMAGE_URL.NEWS_LETTER_PAGE_IMAGE]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/images/news_letter_page_image.png",
  [EIMAGE_URL.CONTACT_PAGE_IMAGE]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/images/contact_page_image.png",
  [EIMAGE_URL.KAKAO_TALK]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/icons/kakaoTalk.png",
  [EIMAGE_URL.QUESTION]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/icons/question.png",
  [EIMAGE_URL.OPEN_GRAPH_DEFAULT_IMAGE]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/images/open_graph_default_image.png",
  [EIMAGE_URL.ECO_LABEL]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/icons/eco_label_mark.png",
  [EIMAGE_URL.EPD]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/icons/eco_score_label_mark.png",
  [EIMAGE_URL.LOW_CARBON]:
    "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/icons/low_carbon_mark.png",
  [EIMAGE_URL.GR]: "https://s3.ap-northeast-2.amazonaws.com/whos.file/assets/icons/gr_mark.png",
  [EIMAGE_URL.KS]: "",
} as const;

import { $sso_axios } from "@/api/axios";
import {
  TCorporationMemberInfoRes,
  TMemberInfoRes,
  TNormalMemberInfoRes,
} from "@/domain/member/api/member.api.type";

export const getAuth = async () => {
  const res = await $sso_axios.get<boolean>("/auth");
  return res.data;
};

export const getMemberInfo = async () => {
  const res = await $sso_axios.get<TMemberInfoRes>(`/sso/users/me`);
  return res.data;
};

export const getNormalMemberInfo = async () => {
  const res = await $sso_axios.get<TNormalMemberInfoRes>(`/member/normal`);
  return res.data;
};

export const getCorporationMemberInfo = async () => {
  const res = await $sso_axios.get<TCorporationMemberInfoRes>(`/member/corporation`);
  return res.data;
};

export const putMemberPhoneNumber = async ({ phoneNumber = "", code = "" }) => {
  await $sso_axios.put(`/member/normal/sms`, { phoneNumber, code });
};

export const putMemberSmsMarketing = async ({ smsMarketing = false }) => {
  await $sso_axios.put(`/member/normal/sms-marketing`, { smsMarketing });
};

export const putMemberName = async ({ name = "" }) => {
  await $sso_axios.put(`/member/normal/name`, { name });
};

export const putMemberEmail = async ({ email = "", code = "" }) => {
  await $sso_axios.put(`/member/normal/email`, { email, code });
};

export const putMemberEmailMarketing = async ({ emailMarketing = false }) => {
  await $sso_axios.put(`/member/normal/email-marketing`, { emailMarketing });
};

export const putMemberMarketing = async ({
  smsMarketing = false,
  mailMarketing = false,
  kakaoMarketing = false,
}) => {
  await $sso_axios.put(`/member/normal/marketing`, {
    smsMarketing,
    mailMarketing,
    kakaoMarketing,
  });
};

export const putMemberPhoneNumberDuplicate = async ({ phoneNumber = "" }) => {
  const res = await $sso_axios.get(`/member/normal/phone-number/${phoneNumber}`);
  return res;
};

export const putMemberEmailDuplicate = async ({ email = "" }) => {
  const res = await $sso_axios.get(`/member/normal/email/${email}`);
  return res;
};

export const putCorporationMemberPhoneNumber = async ({ phoneNumber = "", code = "" }) => {
  await $sso_axios.put(`/member/corporation/sms`, { phoneNumber, code });
};

export const putCorporationMemberSmsMarketing = async ({ smsMarketing = false }) => {
  await $sso_axios.put(`/member/corporation/sms-marketing`, { smsMarketing });
};

export const putCorporationMemberName = async ({ name = "" }) => {
  await $sso_axios.put(`/member/corporation/name`, { name });
};

export const putCorporationMemberEmail = async ({ email = "", code = "" }) => {
  await $sso_axios.put(`/member/corporation/email`, { email, code });
};

export const putCorporationMemberCompanyName = async ({ companyName = "" }) => {
  await $sso_axios.put(`/member/corporation/company-name`, { companyName });
};

export const putCorporationMemberEmailMarketing = async ({ emailMarketing = false }) => {
  await $sso_axios.put(`/member/corporation/email-marketing`, { emailMarketing });
};

export const putCorporationMemberMarketing = async ({
  smsMarketing = false,
  mailMarketing = false,
  kakaoMarketing = false,
}) => {
  await $sso_axios.put(`/member/corporation/marketing`, {
    smsMarketing,
    mailMarketing,
    kakaoMarketing,
  });
};

export const putCorporationMemberPhoneNumberDuplicate = async ({ phoneNumber = "" }) => {
  const res = await $sso_axios.get(`/member/corporation/manager-phone-number/${phoneNumber}`);
  return res;
};

export const putCorporationMemberEmailDuplicate = async ({ email = "" }) => {
  const res = await $sso_axios.get(`/member/corporation/email/${email}`);
  return res;
};

export const putCorporationBusinessNumberDuplicate = async ({ businessLicenseNumber = "" }) => {
  const res = await $sso_axios.get(
    `/member/corporation/business-license-number/${businessLicenseNumber}`
  );
  return res;
};
